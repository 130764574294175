import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const ServiceOne = ({ textAlign, serviceStyle }) => {
    const { t } = useTranslation();

    const services = [
        { icon: <FiActivity />, title: t('services.ga4Implementations'), description: t('services.ga4ImplementationsDesc') },
        { icon: <FiCast />, title: t('services.ga4Migrations'), description: t('services.ga4MigrationsDesc') },
        { icon: <FiMap />, title: t('services.customMlSolutions'), description: t('services.customMlSolutionsDesc') }
    ];

    return (
        <div className="row row--15 service-wrapper">
            {services.map((service, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <Fade top>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">{service.icon}</div>
                            <div className="content">
                                <h4 className="title w-600">
                                    <Link to="#service">{service.title}</Link>
                                </h4>
                                <p className="description b1 color-gray mb--0">{service.description}</p>
                            </div>
                        </div>
                    </Fade>
                </div>
            ))}
        </div>
    );
};
export default ServiceOne;
