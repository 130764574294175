import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import React from "react";

const SEO = ({ title, description, url, image }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title || "Flores Analytics Consulting"}</title>
            <meta name="robots" content="index, follow" />
            <meta name="description" content={description || "Flores Analytics Consulting specializes in digital analytics and GA4 migrations."} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

            {/* Open Graph Tags */}
            <meta property="og:title" content={title || "Flores Analytics Consulting"} />
            <meta property="og:description" content={description || "Flores Analytics Consulting - Your expert in digital analytics."} />
            <meta property="og:url" content={url || "https://floresac.com"} />
            <meta property="og:image" content={image || "https://floresac.com/images/logo/logo.png"} />
            <meta property="og:type" content="website" />

            {/* Twitter Card Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title || "Flores Analytics Consulting"} />
            <meta name="twitter:description" content={description || "Expert digital analytics and GA4 migrations."} />
            <meta name="twitter:image" content={image || "https://floresac.com/images/logo/logo.png"} />

            {/* Canonical Link */}
            <link rel="canonical" href={url || "https://floresac.com"} />
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
};

export default SEO;
