import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";
import { useTranslation } from 'react-i18next';

const TestimonialThree = ({teamStyle}) => {
    const { t } = useTranslation();

    const TestimonialData = [
        {
            form: t("testimonials.1800Flowers.form"),
            description: t("testimonials.1800Flowers.description"),
            name: t("testimonials.1800Flowers.name"),
            subtitle: t("testimonials.1800Flowers.subtitle"),
            image: t("testimonials.1800Flowers.image")
        },
        {
            form: t("testimonials.HarryDavid.form"),
            description: t("testimonials.HarryDavid.description"),
            name: t("testimonials.HarryDavid.name"),
            subtitle: t("testimonials.HarryDavid.subtitle"),
            image: t("testimonials.HarryDavid.image")
        },
        {
            form: t("testimonials.PersonalizationMall.form"),
            description: t("testimonials.PersonalizationMall.description"),
            name: t("testimonials.PersonalizationMall.name"),
            subtitle: t("testimonials.PersonalizationMall.subtitle"),
            image: t("testimonials.PersonalizationMall.image")
        },
        {
            form: t("testimonials.PopcornFactory.form"),
            description: t("testimonials.PopcornFactory.description"),
            name: t("testimonials.PopcornFactory.name"),
            subtitle: t("testimonials.PopcornFactory.subtitle"),
            image: t("testimonials.PopcornFactory.image")
        },
        {
            form: t("testimonials.SharisBerries.form"),
            description: t("testimonials.SharisBerries.description"),
            name: t("testimonials.SharisBerries.name"),
            subtitle: t("testimonials.SharisBerries.subtitle"),
            image: t("testimonials.SharisBerries.image")
        },
        {
            form: t("testimonials.CherylsCookies.form"),
            description: t("testimonials.CherylsCookies.description"),
            name: t("testimonials.CherylsCookies.name"),
            subtitle: t("testimonials.CherylsCookies.subtitle"),
            image: t("testimonials.CherylsCookies.image")
        },
        {
            form: t("testimonials.SimplyChocolate.form"),
            description: t("testimonials.SimplyChocolate.description"),
            name: t("testimonials.SimplyChocolate.name"),
            subtitle: t("testimonials.SimplyChocolate.subtitle"),
            image: t("testimonials.SimplyChocolate.image")
        },
        {
            form: t("testimonials.PlantsDotCom.form"),
            description: t("testimonials.PlantsDotCom.description"),
            name: t("testimonials.PlantsDotCom.name"),
            subtitle: t("testimonials.PlantsDotCom.subtitle"),
            image: t("testimonials.PlantsDotCom.image")
        },
        {
            form: t("testimonials.WolfermansBakery.form"),
            description: t("testimonials.WolfermansBakery.description"),
            name: t("testimonials.WolfermansBakery.name"),
            subtitle: t("testimonials.WolfermansBakery.subtitle"),
            image: t("testimonials.WolfermansBakery.image")
        },
        {
            form: t("testimonials.Stockyards.form"),
            description: t("testimonials.Stockyards.description"),
            name: t("testimonials.Stockyards.name"),
            subtitle: t("testimonials.Stockyards.subtitle"),
            image: t("testimonials.Stockyards.image")
        },
        {
            form: t("testimonials.VitalChoice.form"),
            description: t("testimonials.VitalChoice.description"),
            name: t("testimonials.VitalChoice.name"),
            subtitle: t("testimonials.VitalChoice.subtitle"),
            image: t("testimonials.VitalChoice.image")
        },
        {
            form: t("testimonials.Napco.form"),
            description: t("testimonials.Napco.description"),
            name: t("testimonials.Napco.name"),
            subtitle: t("testimonials.Napco.subtitle"),
            image: t("testimonials.Napco.image")
        }
    ];
    
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















