import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";
import { useLocation } from 'react-router-dom';


const Portfolio =({ switchLanguage, language }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const initialLoad = useRef(true); // Track if it's the initial load

    useEffect(() => {
        if (initialLoad.current) {
            const params = new URLSearchParams(location.search);
            const lang = params.get('lang');
            if (lang && (lang === 'en' || lang === 'es') && lang !== i18n.language) {
                switchLanguage(lang);
            }
            initialLoad.current = false; // Mark initial load as complete
        }
    }, [location.search, switchLanguage, i18n.language]);
    return (
        <>
            <SEO title="Portfolio || Doob - React Business  Template" />
            <Layout switchLanguage={switchLanguage} language={language}>

                <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Portfolio"
                />
                
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Portfolio Default"
                                            title = "You can customize everything!"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio" />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Portfolio;
