import React from 'react';
import { Link } from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const FooterTwo = () => {
    const { t } = useTranslation();

    const footerItemFive = footerOne[5];
    
    // Social media links
    const socialLinks = [
        { platform: 'linkedin', url: 'https://www.linkedin.com/in/manuel-flores-ramirez-976729139/', icon: <FiLinkedin /> }
    ];

    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget */}
                            <div style={{ textAlign: "center" }} className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{t("footerTitle")}</h4>
                                    <div className="inner">
                                        <h6 style={{ paddingRight: "0" }} className="subtitle">{t("footerSubtitle")}</h6>
                                        <ul className="social-icon social-default">
                                            {socialLinks.map((link, index) => (
                                                <li key={index}>
                                                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                        {link.icon}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget */}
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    );
}

export default FooterTwo;
