import React, { useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { FiArrowRight } from "react-icons/fi";
import Logo from "../logo/Logo";
import { useTranslation } from 'react-i18next';

const CalltoActionSeven = () => {
    const { t } = useTranslation();

    // Load Calendly script once
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Clean up script on unmount
        };
    }, []);

    // Function to launch Calendly popup and send GTM event
    const openCalendly = () => {
        // Send custom GTM event to dataLayer
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'calendly_conversion', // Custom event name
                eventCategory: 'Calendly',
                eventAction: 'open',
                eventLabel: 'Calendly Widget Opened',
            });
        }

        // Open Calendly widget
        window.Calendly.initPopupWidget({
            url: 'https://calendly.com/manuel-floresac/30min', // Replace with your actual Calendly link
        });
    };

    return (
        <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-md-8">
                        <div className="inner">
                            <div className="content text-start">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                />
                                <Fade top>
                                    <p className="subtitle">{t("ctaSubtitle")}</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <Fade top>
                            <div className="call-to-btn text-start mt_sm--20 text-md-end">
                                <button 
                                    className="btn-default btn-icon" 
                                    onClick={openCalendly} // Open Calendly and push GTM event
                                >
                                    {t("ctaButtonText")} <i className="icon"><FiArrowRight /></i>
                                </button>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalltoActionSeven;
