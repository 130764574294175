import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n'; // Import i18n configuration here
import { I18nextProvider } from 'react-i18next'; // Import I18nextProvider
import i18n from './i18n'; // Import i18n configuration
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <React.StrictMode>
  <I18nextProvider i18n={i18n}>
   <App />
  </I18nextProvider>
 </React.StrictMode>
);