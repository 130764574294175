import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiHeadphones, FiMail, FiCalendar } from "react-icons/fi";
import ContactForm from "./ContactForm";

const ContactOne = () => {
    const { t } = useTranslation();

    useEffect(() => {
        // Load Calendly script only once when the component mounts
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // Function to open Calendly popup and send GTM event
    const openCalendly = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'calendly_conversion',
                eventCategory: 'Calendly',
                eventAction: 'open',
                eventLabel: 'Calendly Popup Opened from Contact Page',
            });
        }
        window.Calendly.initPopupWidget({
            url: 'https://calendly.com/manuel-floresac/30min', 
        });
    };
    useEffect(() => {
        // Check if a Calendly button or badge exists and add GTM tracking
        const calendlyButton = document.querySelector('.calendly-badge-widget') || document.querySelector('.calendly-popup-button');

        const handleCalendlyClick = () => {
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'calendly_conversion',
                    eventCategory: 'Calendly',
                    eventAction: 'open',
                    eventLabel: 'Calendly Badge/Button Opened from Contact Page',
                });
            }
        };

        if (calendlyButton) {
            calendlyButton.addEventListener('click', handleCalendlyClick);
        }

        // Cleanup listener on component unmount
        return () => {
            if (calendlyButton) {
                calendlyButton.removeEventListener('click', handleCalendlyClick);
            }
        };
    }, []);

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon" onClick={openCalendly}>
                                        <FiCalendar />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t("bookConsultation")}</h4>
                                        <p>
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    // Enviar evento a dataLayer para Google Tag Manager
                                                    if (window.dataLayer) {
                                                        window.dataLayer.push({
                                                            event: 'calendly_conversion',
                                                            eventCategory: 'Calendly',
                                                            eventAction: 'open',
                                                            eventLabel: 'Calendly Widget Opened from Reserve Button',
                                                        });
                                                    }

                                                    // Abrir el widget de Calendly
                                                    window.Calendly.initPopupWidget({ url: 'https://calendly.com/manuel-floresac/30min' });
                                                    return false; // Prevenir el comportamiento por defecto del enlace
                                                }}
                                                style={{ color: '#007bff', textDecoration: 'none' }}
                                            >
                                                {t("reserveTime")}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t("giveUsCall")}</h4>
                                        <p><a href="tel:+7864106890">{t("callNumber")}</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t("sendEmail")}</h4>
                                        <p><a href="mailto:manuel@floresac.info">{t("emailAddress")}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                className="alternative-contact-title"
                style={{
                    textAlign: 'center',
                    margin: '20px'
                }}
            >
                <h3 style={{ fontSize: '3rem', fontWeight: 'bold', color: '#fff' }}>{t("alternativeContactTitle")}</h3>
                <p style={{ fontSize: '2rem', color: '#aaa' }}>
                    {t("alternativeContactText")}
                </p>
            </div>
            <div className="row mt--40 row--15">
                <div className="col-lg-12">
                    <ContactForm formStyle="contact-form-1" />
                </div>
            </div>
        </>
    )
}

export default ContactOne;
