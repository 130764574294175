import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import  { useState } from 'react';
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // Ensure this file initializes i18next
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData_en from '../data/blog/BlogList.json';
import BlogClassicData_es from '../data/blog/BlogList_es.json';
import { useLocation } from 'react-router-dom';



const HomeDefault = ({ switchLanguage, language }) => {
    const { t, i18n } = useTranslation();
    const [BlogListData, setBlogListData] = useState(BlogClassicData_en.slice(0, 3));
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const lang = params.get('lang');
        if (lang && (lang === 'en' || lang === 'es') && lang !== i18n.language) {
            switchLanguage(lang);
        }
    }, [location.search, switchLanguage, i18n.language]);

    useEffect(() => {
        const initCalendlyBadge = () => {
            if (window.Calendly && window.Calendly.initBadgeWidget) {
                window.Calendly.initBadgeWidget({
                    url: 'https://calendly.com/manuel-floresac/30min',
                    text: "Schedule a Call",
                    color: '#fafafa',
                    textColor: '#aa1d2c',
                    branding: false,
                });
            } else {
                console.warn("Calendly script not loaded yet.");
            }
        };

        // Retry if the Calendly object is not yet available
        const retryTimeout = setTimeout(initCalendlyBadge, 1000);

        // Set up an observer for dynamically added Calendly badges
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === "childList") {
                    const calendlyBadge = document.querySelector('.calendly-badge-widget');
                    if (calendlyBadge) {
                        calendlyBadge.addEventListener('click', handleCalendlyClick);
                        observer.disconnect();
                    }
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        const handleCalendlyClick = () => {
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'calendly_conversion',
                    eventCategory: 'Calendly',
                    eventAction: 'open',
                    eventLabel: 'Calendly Badge Opened from Home',
                });
                console.log("Calendly GTM event pushed");
            }
        };

        // Cleanup
        return () => {
            clearTimeout(retryTimeout);
            observer.disconnect();
            const calendlyBadge = document.querySelector('.calendly-badge-widget');
            if (calendlyBadge) {
                calendlyBadge.removeEventListener('click', handleCalendlyClick);
            }
        };
    }, []); // Empty dependency array to initialize only once

    useEffect(() => {
        // Update the Calendly widget text whenever language changes
        const calendlyButton = document.querySelector('.calendly-badge-widget');
        if (calendlyButton) {
            const textNode = calendlyButton.querySelector('.calendly-badge-content');
            if (textNode) {
                textNode.textContent = t("cta.scheduleText");
            }
        }
    }, [i18n.language]);

    useEffect(() => {
        setBlogListData(
            i18n.language === 'es' ? BlogClassicData_es.slice(0, 3) : BlogClassicData_en.slice(0, 3)
        );
    }, [i18n.language]);

    return (
        <>
            <SEO 
                title="Flores Analytics Consulting" 
                description="Welcome to Flores Analytics Consulting, your expert in digital analytics and GA4 migrations."
                url="https://floresac.com/"
                image="https://floresac.com/images/logo/logo.png"   // Your logo or another homepage-related image
            />
            <main className="page-wrapper">
                
                <HeaderOne switchLanguage={switchLanguage} language={language} btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-3-b.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">{t("slider.subtitle")}</span>
                                    <h1 className="title display-one">{t("slider.title")}</h1>
                                    <p className="description">{t("slider.description")}</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">{t("slider.buttonText")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                
                 {/* Start Service Area */}
                 <div className="rn-service-area rn-section-gapBottom pt--100">
                    <div className="container">
                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            subtitle={t("services.subtitle")}
                        />
                        <ServiceOne 
                            serviceStyle="service__style--1 icon-circle-style"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area */}
                
                
                {/* Start Portfolio Area  */}
                
                {/* End Portfolio Area  */}

                <Separator />
                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t("timeline.subtitle")}
                                    title={t("timeline.title")}
                                    description={t("timeline.description")}
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                {/* Start Video Area  */}
            
                {/* End Video Area  */}


                <Separator />

                 {/* Start Testimonial Area */}
                 <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t("testimonials.subtitle")}
                                    title={t("testimonials.title")}
                                    description={t("testimonials.description")}
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>
                {/* End Testimonial Area */}

                <Separator />
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t("blog.subtitle")}
                                    title={t("blog.title")}
                                    description={t("blog.description")}
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Elements Area  */}

                <FooterOne />
                <Copyright />                    

            </main>
            
        </>
    )
}
export default HomeDefault

