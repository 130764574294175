import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";

const Nav = ({ switchLanguage, language }) => {
    const { t } = useTranslation();

    return (
        <ul className="mainmenu">
            <li>
                <Link to="/">{t('home')}</Link>
            </li>
            <li>
                <Link to="/contact">{t('contact')}</Link>
            </li>
            <li className="language-toggle">
                <Link
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        const newLang = language === 'en' ? 'es' : 'en';
                        switchLanguage(newLang); // Update the language without affecting the URL
                    }}
                    className="language-link"
                >
                    <ReactCountryFlag
                        countryCode={language === 'en' ? "US" : "MX"}
                        svg
                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                        title={language === 'en' ? "United States" : "Mexico"}
                    />
                    {language === 'en' ? 'English' : 'Español'}
                </Link>
            </li>
        </ul>
    );
};

export default Nav;
