import React from 'react';
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';



const TimelineOne = () => {
    const { t } = useTranslation();
    const TimelineData = [
        {
            id: "1",
            date: t("timeline.step1Date"),
            title: t("timeline.step1Title"),
            description: t("timeline.step1Description"),
            image: "timeline-01",
            workingStep: [
                {
                    stepTitle: t("timeline.step1Details.identifyKpis").split(' - ')[0],
                    stepDescription: t("timeline.step1Details.identifyKpis").split(' - ')[1],
                },
                {
                    stepTitle: t("timeline.step1Details.identifyPainPoints").split(' - ')[0],
                    stepDescription: t("timeline.step1Details.identifyPainPoints").split(' - ')[1],
                },
                {
                    stepTitle: t("timeline.step1Details.defineTracking").split(' - ')[0],
                    stepDescription: t("timeline.step1Details.defineTracking").split(' - ')[1],
                },
            ]
        },
        {
            id: "2",
            date: t("timeline.step2Date"),
            title: t("timeline.step2Title"),
            description: t("timeline.step2Description"),
            image: "timeline-02",
            workingStep: [
                {
                    stepTitle: t("timeline.step2Details.tools").split(' - ')[0],
                    stepDescription: t("timeline.step2Details.tools").split(' - ')[1],
                },
                {
                    stepTitle: t("timeline.step2Details.projectManagement").split(' - ')[0],
                    stepDescription: t("timeline.step2Details.projectManagement").split(' - ')[1],
                },
            ]
        },
        {
            id: "3",
            date: t("timeline.step3Date"),
            title: t("timeline.step3Title"),
            description: t("timeline.step3Description"),
            image: "timeline-03",
            workingStep: [
                {
                    stepTitle: t("timeline.step3Details.overviewReports").split(' - ')[0],
                    stepDescription: t("timeline.step3Details.overviewReports").split(' - ')[1],
                },
                {
                    stepTitle: t("timeline.step3Details.continuousSupport").split(' - ')[0],
                    stepDescription: t("timeline.step3Details.continuousSupport").split(' - ')[1],
                },
            ]
        },
    ];
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <Fade top>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </Fade>

                                        <Fade top>
                                            <h2 className="title">{data.title}</h2>
                                        </Fade>

                                        <Fade top>
                                            <p className="description">{data.description}</p>
                                        </Fade>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <Fade top>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </Fade>

                                                        <Fade top>
                                                            <p>{data.stepDescription}</p>
                                                        </Fade>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
