import React from 'react';
import HeaderOne from "./header/HeaderOne";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";

const Layout = ({ children, switchLanguage, language }) => {
    return (
        <>
            <main className="page-wrapper">
                <HeaderOne
                    btnStyle="btn-small round btn-icon"
                    HeaderSTyle="header-not-transparent"
                    switchLanguage={switchLanguage}
                    language={language} // Pass language to HeaderOne
                />
                {children}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    );
};

export default Layout;
