import React from 'react';
import { Fade } from "react-awesome-reveal";
import { FiArrowRight } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const CalltoActionFive = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    
    // Get the current language from i18n
    const currentLang = i18n.language;
    
    // Generate contact link with the current language as a query parameter
    const contactLink = `/contact?lang=${currentLang}`;

    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <Fade top>
                                    <h2 className="title">{t("callToAction.title")}</h2>
                                </Fade>
                                <Fade top>
                                    <p className="subtitle">{t("callToAction.subtitle")}</p>
                                </Fade>
                                <Fade top>
                                    <div className="call-to-btn text-center">
                                        <a 
                                            className="btn-default btn-icon" 
                                            href={contactLink} // Use the dynamic link with the current language
                                        >
                                            {t("callToAction.btnText")} <i className="icon"><FiArrowRight /></i>
                                        </a>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalltoActionFive;
