import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const Result = ({ message }) => {
    return <p className="success-message">{message}</p>;
};

function ContactForm({ formStyle }) {
    const { t } = useTranslation();
    const [result, showResult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_4eigads',
                'template_7kefba7',
                e.target,
                'nK6A5zSJgqJsL8GQG'
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        showResult(true);
    };

    setTimeout(() => {
        showResult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="from_name"
                    id="from_name"
                    placeholder={t("formNamePlaceholder")}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={t("formEmailPlaceholder")}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder={t("formPhonePlaceholder")}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder={t("formSubjectPlaceholder")}
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder={t("formMessagePlaceholder")}
                    required
                />
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">{t("formSubmitButton")}</button>
            </div>

            <div className="form-group">
                {result ? <Result message={t("formSuccessMessage")} /> : null}
            </div>
        </form>
    );
}

export default ContactForm;
