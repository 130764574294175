import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import { useLocation } from 'react-router-dom';


const Contact = ({ switchLanguage, language }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const initialLoad = useRef(true); // Track if it's the initial load

    useEffect(() => {
        if (initialLoad.current) {
            const params = new URLSearchParams(location.search);
            const lang = params.get('lang');
            if (lang && (lang === 'en' || lang === 'es') && lang !== i18n.language) {
                switchLanguage(lang);
            }
            initialLoad.current = false; // Mark initial load as complete
        }
    }, [location.search, switchLanguage, i18n.language]);
    return (
        <>
            <SEO title="Contact || Flores Analytics Consulting" />
            {/* Pass switchLanguage and language to Layout */}
            <Layout switchLanguage={switchLanguage} language={language}>
                <div className="main-content">
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle={t("howCanWeHelp")}
                                        title={t("letsGetInTouch")}
                                        description=""
                                    />
                                </div>
                            </div>
                            {/* Pass switchLanguage to ContactOne */}
                            <ContactOne switchLanguage={switchLanguage} />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Contact;
