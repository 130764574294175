import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import PortfolioDetailsContent from "../elements/portfolio/PortfolioDetailsContent";
import PortfolioData from "../data/portfolio/PortfolioData.json";
import SEO from "../common/SEO";
import Layout from "../common/Layout";

const PortfolioDetails = () => {
    const { id } = useParams(); // Get 'id' from route parameters
    const portfolioId = parseInt(id, 10); // Convert the 'id' to an integer

    // Filter portfolio data based on the 'id' parameter
    const data = PortfolioData.filter(portfolio => portfolio.id === portfolioId);

    return (
        <>
            <SEO title="Portfolio Details || Doob" />
            <Layout>
                {data.length > 0 ? (
                    <PortfolioDetailsContent data={data[0]} />
                ) : (
                    <div>Portfolio not found.</div> // Handle case where no data is found
                )}
            </Layout>
        </>
    );
};

export default PortfolioDetails;
