import React from 'react';
import SEO from "../common/SEO";

import Layout from "../common/Layout";


const Company = () => {
    return (
        <>
            <SEO title="Privacy Policy || Flores Analytics Consulting" />
            <Layout>
               
                <div className="main-content">
                <div id="ot-sdk-cookie-policy"></div>
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
                    
                </div>
            </Layout>
        </>
    )
}
export default Company;
