import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";
import { useLocation } from 'react-router-dom';
const PortfolioThreeColumn = ({ switchLanguage, language }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const initialLoad = useRef(true); // Track if it's the initial load

    useEffect(() => {
        if (initialLoad.current) {
            const params = new URLSearchParams(location.search);
            const lang = params.get('lang');
            if (lang && (lang === 'en' || lang === 'es') && lang !== i18n.language) {
                switchLanguage(lang);
            }
            initialLoad.current = false; // Mark initial load as complete
        }
    }, [location.search, switchLanguage, i18n.language]);
    return (
        <>
            <SEO title="Portfolio Three Column || Doob - React Business  Template" />
            <Layout switchLanguage={switchLanguage} language={language}>
                <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Portfolio Three Column"
                />
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Portfolio Three Column"
                                            title = "You can customize everything!"
                                            description = ""
                                        />
                                </div>
                            </div>
                            
                            <div class="product">
                                <h2>All-in-One E-Commerce with Analytics</h2>
                                <p>An all-in-one e-commerce solution with integrated analytics. Easily manage your store and track customer data.</p>
                                
                                <span>Price: $199.99</span>
                                <img src="images/portfolio/portfolio-01.jpg" alt="All-in-One E-Commerce" />

                                <button
                                    class="snipcart-add-item"
                                    data-item-id="product-1"
                                    data-item-name="All-in-One E-Commerce with Analytics"
                                    data-item-description="An all-in-one e-commerce solution with integrated analytics."
                                    data-item-price="199.99"
                                    data-item-image="images/portfolio/portfolio-01.jpg"
                                    data-item-url="/portfolio-details/1"
                                > 
                                    Add to Cart
                                </button>
                            </div>
                            <PortfolioOne Column="col-lg-4 col-md-6 mt--30 portfolio"  />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PortfolioThreeColumn;
